import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {goBack, goTo} from '@/utils';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {
  BalanceListItem,
  PayMethod,
  getBalanceList,
  getPayMethod,
  goIncome,
  paySuccess,
} from './recharge.service';
import Spin from '@/components/basic/spin';
import {Success, upiPayment} from '@/utils';
import RechargeBalance from './recharge-balance';
import RechargeSelect from './recharge-select';
import RechargeChannel from './recharge-channel';
import RechargeButton from '@/components/business/recharge-button';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {useFocusEffect} from '@react-navigation/native';
import {getBalance} from '@/services/global.service';
import {useScreenSize} from '../hooks/size.hooks';

const Recharge = () => {
  const {i18n} = useTranslation();
  const [balanceList, setBalanceList] = React.useState<BalanceListItem[]>([]);
  const [paymethodList, setPaymenthodList] = React.useState<PayMethod[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState('');
  const {calcActualSize} = useScreenSize();
  const [payMethodId, setPayMethodId] = React.useState<number>();
  const payMethodItem = useMemo(() => {
    return paymethodList.find(p => p.id === payMethodId);
  }, [paymethodList, payMethodId]);
  const canShowBalanceList = useMemo(() => {
    return balanceList.filter(
      _balance =>
        _balance.balance >= (payMethodItem?.minAmount || 0) &&
        _balance.balance <=
          (payMethodItem?.maxAmount || Number.MAX_SAFE_INTEGER),
    );
  }, [balanceList, payMethodItem]);
  const balanceId = useMemo(() => {
    const item = canShowBalanceList.find(b => b.balance === +balance);
    return item ? item.id + '' : '';
  }, [canShowBalanceList, balance]);
  const [amount, setAmount] = useState<number>(0);
  const [incomeInfo, setIncomeInfo] = React.useState({
    upiId: '',
    orderNo: '',
  });
  React.useEffect(() => {
    setLoading(true);
    Promise.all([getBalanceList(), getPayMethod()])
      .then(([blance, paymenthod]) => {
        setBalanceList(blance);
        // setBalance((blance[0]?.balance || '') + '');
        setPaymenthodList(paymenthod);
        setPayMethodId(paymenthod[0].id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setBalance((canShowBalanceList[0]?.balance || '') + '');
  }, [canShowBalanceList]);

  const handleRefresh = useCallback(() => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    setLoading(true);
    getBalance('')
      .then(res => {
        setAmount(res.mainBalance);
      })
      .finally(() => {
        setLoading(false);
      });
    // globalStore.updateAmount.next({});
  }, []);

  useFocusEffect(handleRefresh);

  const handleGotoRecords = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    goTo('RechargeRecords');
  };

  // 支付成功回调
  const onSuccess = (success: Success) => {
    if (success.status === 'SUCCESS') {
      paySuccess({
        orderNo: incomeInfo.orderNo,
        tradeResult: '1',
        approvalUrt: success.approvalRefNo,
      })
        .then()
        .finally(() => {
          globalStore.updateAmount.next({});
        });
    } else {
      // crashlytics().recordError(
      //   new Error(
      //     JSON.stringify({
      //       type: '支付成功,但状态不是success',
      //       incomeInfo: incomeInfo,
      //       successCB: success,
      //     }),
      //   ),
      // );
    }
  };
  const onFailure = (error: Error) => {
    globalStore.globalWaringTotal(error.message);
  };

  const handleRecharge = async () => {
    const {minAmount, maxAmount} = payMethodItem || {};
    if (
      !balance ||
      +balance <= 0 ||
      (minAmount && +balance < minAmount) ||
      (maxAmount && +balance > maxAmount)
    ) {
      globalStore.globalWaringTotal(i18n.t('recharge-page.tip.money-error'));
      return;
    }
    if (!payMethodItem) {
      globalStore.globalWaringTotal(
        i18n.t('recharge-page.tip.paymethod-error'),
      );
      return;
    }
    setLoading(true);
    goIncome({
      balanceId: balanceId || 0,
      payTag: payMethodItem.payTag,
      payTypeId: payMethodId + '',
      rechargeBalance: balanceId ? 0 : balance,
    })
      .then(res => {
        if (typeof res === 'string') {
          if (res.indexOf('#amount#') > -1) {
            res = res.replace('#amount#', balance);
          }
          if (res.indexOf('?') > -1) {
            res += '&lang=' + globalStore.lang;
          } else {
            res += '?lang=' + globalStore.lang;
          }
          if (globalStore.isWeb) {
            location.href = res;
          } else {
            goTo('WebView', {
              originUrl: res,
              header: true,
              headerTitle: i18n.t('label.recharge'),
              serverRight: true,
              hideAmount: true,
            });
          }
        } else {
          setIncomeInfo(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // 吊起APP支付
  const onPay = () => {
    const config = {
      payeeVpa: incomeInfo.upiId,
      payeeName: incomeInfo.upiId,
      merchantCode: incomeInfo.upiId,
      transactionId: incomeInfo.orderNo,
      transactionRefId: incomeInfo.orderNo,
      description: incomeInfo.orderNo,
      amount: balance + '',
    };
    upiPayment.initiate('net.one97.paytm', '', config, onSuccess, onFailure);
  };

  React.useEffect(() => {
    if (incomeInfo.orderNo && incomeInfo.upiId) {
      onPay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeInfo]);

  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}>
      <DetailNavTitle
        onBack={goBack}
        serverRight
        hideAmount
        title={i18n.t('label.recharge')}
      />
      <Spin loading={loading} style={[theme.flex.flex1, theme.flex.col]}>
        <View
          style={[
            theme.flex.flex1,
            theme.flex.basis0,
            {
              paddingBottom: calcActualSize(theme.paddingSize.s),
            },
          ]}>
          <ScrollView
            contentContainerStyle={[
              {
                paddingTop: calcActualSize(theme.paddingSize.l),
                paddingHorizontal: calcActualSize(theme.paddingSize.l),
                gap: calcActualSize(theme.paddingSize.l),
              },
            ]}>
            <RechargeBalance
              balance={amount}
              payMethod={payMethodItem?.payName}
              onRefresh={handleRefresh}
              onGotoRecords={handleGotoRecords}
            />
            <RechargeSelect
              min={payMethodItem?.minAmount || 0}
              max={payMethodItem?.maxAmount || 0}
              balance={balance}
              balanceList={canShowBalanceList}
              onChangeBalance={setBalance}
            />
            <RechargeChannel
              payMethodList={paymethodList}
              onPayMethodChange={setPayMethodId}
              payMethodId={payMethodId}
            />
          </ScrollView>
        </View>
        <RechargeButton
          type="linear-primary"
          disabled={balance === '' || +balance <= 0}
          onRecharge={handleRecharge}
        />
      </Spin>
    </View>
  );
};

export default Recharge;
